@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply dark:bg-gray-800 bg-white text-gray-800 dark:text-white
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.trend-weekday-label {
  @apply text-center text-lg mb-2;
}

.center-div {
  @apply flex items-center h-40 justify-center dark:bg-gray-800 rounded-md mx-2 my-4 py-3
}

.data-placeholder {
  @apply mb-4 text-2xl italic text-gray-600 dark:text-gray-400 
}

.weekday-div {
  @apply mb-4 dark:bg-gray-800 rounded-md mx-2 py-3
}
